@media screen and (max-width: 500px) {
  #features {
    padding: 0px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }

  
}


.slide{
  height: 400px;
  margin-top: 80px;
}
.slide img {
  width: 100%;
}

@media screen and (max-width: 500px){
  
.slide{
  height:200px;
}

.slide img {
  width: 100%;
  height: 100%;
}

}


/* Add these styles to your CSS file or React component styles */
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.image-container img {
  width: 100%; /* Make images take 100% of the container's width */
  max-width: 300px; /* Set a maximum width for the images */
  height: auto; /* Maintain the aspect ratio of the images */
  margin: 10px; /* Add some margin between images for spacing */
}

/* Add this CSS for responsive layout */
@media (max-width: 767px) {
  #features .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #features .col-xs-6 {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px; /* Add spacing between items */
    font-size: 16px;
  }

  

}


.gents{
  width: 100%;
  height: auto;
  background-color: black;
  margin-top: 120px;
  color: white;
}

.gents h1 {
  text-align: center;
  padding-top: 30px;
}
.gents p {
  margin-left: 20px;
  padding-top: 20px;
}

@media (max-width: 767px){

  .gents{
    width: 100%;
    height: 490px;
    background-color: black;
    margin-top: 120px;
    color: white;
  }
  
  .gents h1 {
    text-align: center;
    padding-top: 30px;
    font-size: 25px;
  }
  .gents p {
    margin-left: 20px;
    padding-top: 20px;
  }

}